import Auth0ProviderWithHistory from 'auth0-provider-with-history';
import { Toaster } from 'components/shadcn/toaster';
import { Settings } from 'luxon';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (!process.env.REACT_APP_AUTH_DOMAIN || !process.env.REACT_APP_AUTH_CLIENT_ID) {
  throw new Error('Missing Auth0 configuration');
}

const container = document.getElementById('root');
const root = createRoot(container);

// Set default locale
Settings.defaultLocale = 'en';

root.render(
  <NuqsAdapter>
    <React.StrictMode>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
          <Toaster />
        </Auth0ProviderWithHistory>
      </Router>
    </React.StrictMode>
  </NuqsAdapter>
);

reportWebVitals(console.log);
